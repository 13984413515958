import axios from "@axios"

export default {
  namespaced: true,
  state: {
    professionals: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getProfessionalById: (state) => (id) => {
      return state.professionals.find(professional => professional.id === id)
    }
  },
  mutations: {
    SET_PROFESSIONALS(state, professionals) {
      state.professionals = professionals
    },
    ADD_PROFESSIONAL(state, item){
      state.professionals.unshift(item)
    },
    UPDATE_PROFESSIONAL(state, professional) {
      const professionalIndex = state.professionals.findIndex((p) => p.id == professional.id)
      Object.assign(state.professionals[professionalIndex], professional)
    },
    REMOVE_PROFESSIONAL(state, id) {
      const professionalIndex = state.professionals.findIndex((p) => p.id == id)
      state.professionals.splice(professionalIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchProfessionals({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/professionals', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_PROFESSIONALS', data.data)
          } else {
            commit('SET_PROFESSIONALS', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/professionals/${args.professional_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                  commit('SET_PROFESSIONALS', data.data)

              } else {
                  commit('SET_PROFESSIONALS', data)

              }

              commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)

              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/professionals', {
              ...data
          }).then((response) => {
              commit('ADD_PROFESSIONAL', response)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
          axios.put(`/professionals/${data.id}`, {...data })
              .then((response) => {
                  context.commit('UPDATE_PROFESSIONAL', response.data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/professionals/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_PROFESSIONAL', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
