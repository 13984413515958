import axios from "@axios"

export default {
  namespaced: true,
  state: {
    emailReset: [],
    codeReset: [],
    passwordReset: []
  },
  getters: {},
  mutations: {
    ADD_EMAIL(state, item){
      state.emailReset.unshift(item)
    },
    ADD_CODE(state, item){
      state.codeReset.unshift(item)
    },
    ADD_PASSWORD(state, item){
      state.passwordReset.unshift(item)
    }

  },
  actions: {
    emailRecover({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/recover-password', {
              ...data
          }).then((response) => {
              commit('ADD_EMAIL', response.data.emailReset)
              localStorage.setItem(
                "email", JSON.stringify(data)
              )
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
    codeRecover({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/verify-code', {
              ...data
          }).then((response) => {
              commit('ADD_CODE', response.data.codeReset)
              localStorage.setItem(
                "id", JSON.stringify(response.data.id)
              )
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
    newPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/new-password', {
              ...data
          }).then((response) => {
              commit('ADD_PASSWORD', response.data.passwordReset)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
  },
}
