import axios from "@axios"

export default {
  namespaced: true,
  state: {
    segments: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getSegmentsById: (state) => (id) => {
      return state.segments.find(segment => segment.id === id)
    }
  },
  mutations: {
    SET_SEGMENTS(state, segments) {
      state.segments = segments
    },
    ADD_SEGMENT(state, item){
      state.segments.unshift(item)
    },
    UPDATE_SEGMENT(state, segment) {
      const segmentsIndex = state.segments.findIndex((p) => p.id == segment.id)
      Object.assign(state.segments[segmentsIndex], segment)
    },
    REMOVE_SEGMENT(state, id) {
      const segmentsIndex = state.segments.findIndex((p) => p.id == id)
      state.segments.splice(segmentsIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchSegments({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/segments', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_SEGMENTS', data.data)
          } else {
            commit('SET_SEGMENTS', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/segments/${args.segment_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                commit('SET_SEGMENTS', data.data)
              } else {
                commit('SET_SEGMENTS', data)
              }
              commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)
              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("icon", data.icon );
        formData.append("name", data.name );
          axios.post('/segments', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        }).then((response) => {
              commit('ADD_SEGMENT', response.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.icon){
          formData.append("icon", data.icon );
        }
        formData.append("name", data.name );
        axios.post(`/segments/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
            context.commit('UPDATE_SEGMENT', response.data)
            resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/segments/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_SEGMENT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
