import axios from "@axios"

export default {
  namespaced: true,
  state: {
    countUser: [],
    topCompanies: [],
    userWeek: [],
    countHealth: [],
    accessSystem: [],
    recordAccess: []
  },
  getters: {},
  mutations: {
    SET_COUNT_USER(state, countUser) {
      state.countUser = countUser
    },
    SET_COUNT_HEALTH(state, countHealth) {
      state.countHealth = countHealth
    },
    SET_TOP_COMPANY(state, topCompanies) {
      state.topCompanies = topCompanies
    },
    SET_USER_WEEK(state, userWeek) {
      state.userWeek = userWeek
    },
    SET_ACCESS_SYSTEM(state, accessSystem){
      state.accessSystem = accessSystem
    },
    SET_RECORD_ACCESS(state, recordAccess){
      state.recordAccess = recordAccess
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchCountUser({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/count-user', {
          params: args,
        }).then(({ data }) => {
            commit('SET_COUNT_USER', data),         
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchCountHealth({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/count-health', {
          params: args,
        }).then(({ data }) => {
            commit('SET_COUNT_HEALTH', data),         
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchReferenceMonths({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/top-clicks-reference-months').then(({ data }) => {
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchTopCompany({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.post(`/top-clicks`, {
          ...args,
        }).then(({ data }) => {
            commit('SET_TOP_COMPANY', data),         
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchUserWeek({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/user-week', {
          params: args,
        }).then(({ data }) => {
            commit('SET_USER_WEEK', data),         
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchAccessSystem({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/access-system', {
          params: args,
        }).then(({ data }) => {
            commit('SET_ACCESS_SYSTEM', data),         
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchRecordAccess({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/record-access', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_RECORD_ACCESS', data.data)
          } else {
            commit('SET_RECORD_ACCESS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
