import Vue from 'vue'
import Router from 'vue-router'
import { isSignedIn } from '@/auth'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [

    // Precisa do token

    {
      path: '',
      component: ()=> import('@/layouts/Main'),
      beforeEnter(_, __, next) {
        if (isSignedIn()){
          next();
          return;
        }
        next('/login')
      },
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            pageTitle: 'Home',
            breadcrumb: [
              {
                text: 'Home',
                active: true,
              },
            ],
          },
        },
        {
          path: '/idosos',
          name: 'elderlys',
          component: () => import('@/views/elderly/Index.vue'),
          meta: {
            pageTitle: 'Idosos',
            breadcrumb: [
              {
                text: 'Idosos',
                active: true,
              },
            ],
          },
        },
        {
          path: '/idoso/:id/perfil',
          name: 'profileElderly',
          component: () => import('@/views/elderly/Profile.vue'),
          meta: {
            pageTitle: 'Perfil',
            breadcrumb: [
              {
                text: 'Perfil',
                active: true,
              },
            ],
          },
        },
        {
          path: '/responsaveis',
          name: 'responsibles',
          component: () => import('@/views/responsible/Index.vue'),
          meta: {
            pageTitle: 'Responsáveis',
            breadcrumb: [
              {
                text: 'Responsáveis',
                active: true,
              },
            ],
          },
        },
        {
          path: '/responsavel/:id/perfil',
          name: 'profile',
          component: () => import('@/views/responsible/Profile.vue'),
          meta: {
            pageTitle: 'Perfil',
            breadcrumb: [
              {
                text: 'Perfil',
                active: true,
              },
            ],
          },
        },
        {
          path: '/mensagens',
          name: 'notification',
          component: () => import('@/views/notification/Index.vue'),
          meta: {
            pageTitle: 'Mensagens',
            breadcrumb: [
              {
                text: 'Mensagens',
                active: true,
              },
            ],
          },
        },
        {
          path: '/banners',
          name: 'banner',
          component: () => import('@/views/banner/Index.vue'),
          meta: {
            pageTitle: 'Banner',
            breadcrumb: [
              {
                text: 'Banner',
                active: true,
              },
            ],
          },
        },
        {
          path: '/videos',
          name: 'videos',
          component: () => import('@/views/videos/Index.vue'),
          meta: {
            pageTitle: 'Vídeos',
            breadcrumb: [
              {
                text: 'Vídeos',
                active: true,
              },
            ],
          },
        },
        {
          path: '/medicamentos',
          name: 'medicaments',
          component: () => import('@/views/medicaments/Index.vue'),
          meta: {
            pageTitle: 'Medicamentos',
            breadcrumb: [
              {
                text: 'Medicamentos',
                active: true,
              },
            ],
          },
        },
        // {
        //   path: '/farmacias',
        //   name: 'pharmacies',
        //   component: () => import('@/views/pharmacie/Index.vue'),
        //   meta: {
        //     pageTitle: 'Farmacias',
        //     breadcrumb: [
        //       {
        //         text: 'Farmacias',
        //         active: true,
        //       },
        //     ],
        //   },
        // },
        {
          path: '/termos',
          name: 'terms',
          component: () => import('@/views/term/Index.vue'),
          meta: {
            pageTitle: 'Termos',
            breadcrumb: [
              {
                text: 'Termos',
                active: true,
              },
            ],
          },
        },
        {
          path: '/categorias ',
          name: 'categories',
          component: () => import('@/views/category/Index.vue'),
          meta: {
            pageTitle: 'Categorias ',
            breadcrumb: [
              {
                text: 'Categorias ',
                active: true,
              },
            ],
          },
        },
        {
          path: '/planos ',
          name: 'plans',
          component: () => import('@/views/plan/Index.vue'),
          meta: {
            pageTitle: 'Planos',
            breadcrumb: [
              {
                text: 'Planos',
                active: true,
              },
            ],
          },
        },
        {
          path: '/email ',
          name: 'emails',
          component: () => import('@/views/email/Index.vue'),
          meta: {
            pageTitle: 'Webmail',
            breadcrumb: [
              {
                text: 'Webmail',
                active: true,
              },
            ],
          },
        },
        {
          path: '/analytcs ',
          name: 'analytcs',
          component: () => import('@/views/analytcs/Index.vue'),
          meta: {
            pageTitle: 'Firebase Analytcs',
            breadcrumb: [
              {
                text: 'Analytcs',
                active: true,
              },
            ],
          },
        },
        {
          path: '/farmaceutica',
          name: 'amountTypes',
          component: () => import('@/views/amountType/Index.vue'),
          meta: {
            pageTitle: 'Forma Farmacêutica',
            breadcrumb: [
              {
                text: 'Forma Farmacêutica',
                active: true,
              },
            ],
          },
        },
        {
          path: '/profissionais',
          name: 'professionals',
          component: () => import('@/views/professionals/Index.vue'),
          meta: {
            pageTitle: 'Profissionais',
            breadcrumb: [
              {
                text: 'Profissionais',
                active: true,
              },
            ],
          },
        },
        {
          path: '/profissionais/sugestoes',
          name: 'suggest',
          component: () => import('@/views/professionals/Suggest.vue'),
          meta: {
            pageTitle: 'Sugestões',
            breadcrumb: [
              {
                text: 'Sugestões',
                active: true,
              },
            ],
          },
        },
        {
          path: '/empresas',
          name: 'companies',
          component: () => import('@/views/companies/Index.vue'),
          meta: {
            pageTitle: 'Empresas',
            breadcrumb: [
              {
                text: 'Empresas',
                active: true,
              },
            ],
          },
        },
        {
          path: '/categorias',
          name: 'companiesCategory',
          component: () => import('@/views/companies/IndexType.vue'),
          meta: {
            pageTitle: 'Categorias',
            breadcrumb: [
              {
                text: 'Categorias',
                active: true,
              },
            ],
          },
        },
        {
          path: '/empresa/:id_company/exclusiva',
          name: 'companyExclusive',
          component: () => import('@/views/exclusive/Index.vue'),
          meta: {
            pageTitle: 'Empresa exclusiva',
            breadcrumb: [
              {
                text: 'Empresa exclusiva',
                active: true,
              },
            ],
          },
        },
      ]
    },

    // Não precisa do token

    {
      path: '',
      component: () => import('@/App.vue'),
      beforeEnter(_, __, next){
        if (!isSignedIn()){
          next();
          return;
        }
        next('/')
      },
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('@/views/auth/Login.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/privacidade',
          name: 'privacidade',
          component: () => import('@/views/privacity/Index.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/recuperacao',
          name: 'forgotPassword',
          component: () => import('@/views/auth/ForgotPassword.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/codigo',
          name: 'codepassword',
          component: () => import('@/views/auth/CodeReset.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/novasenha',
          name: 'resetpassword',
          component: () => import('@/views/auth/ResetPassword.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '/error-404',
          name: 'error-404',
          component: () => import('@/views/error/Error404.vue'),
          meta: {
            layout: 'full',
          },
        },
        {
          path: '*',
          redirect: 'error-404',
        },
      ]
    },

  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
