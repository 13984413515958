import axios from "@axios"

export default {
  namespaced: true,
  state: {
    medicaments: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getMedicamentsById: (state) => (id) => {
      return state.medicaments.find(medicament => medicament.id === id)
    }
  },
  mutations: {
    SET_MEDICAMENTS(state, medicaments) {
      state.medicaments = medicaments
    },
    ADD_MEDICAMENT(state, item){
      state.medicaments.unshift(item)
    },
    UPDATE_MEDICAMENT(state, medicament) {
      const medicamentIndex = state.medicaments.findIndex((p) => p.id == medicament.data.id)
      Object.assign(state.medicaments[medicamentIndex], medicament.data)
    },
    REMOVE_MEDICAMENT(state, id) {
      const medicamentIndex = state.medicaments.findIndex((p) => p.id == id)
      state.medicaments.splice(medicamentIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchMedicaments({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/medicaments', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_MEDICAMENTS', data.data)
          } else {
            commit('SET_MEDICAMENTS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/medicaments', {
              ...data
          }).then((response) => {
              commit('ADD_MEDICAMENT', response.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    import({ commit }, data) {
      return new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("excel", data.excel );
          axios.post('/import-medicament', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then((response) => {
              commit('ADD_MEDICAMENT', response.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/medicaments/${data.id}`, {...data })
          .then((response) => {
            context.commit('UPDATE_MEDICAMENT', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/medicaments/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_MEDICAMENT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
  },
}
