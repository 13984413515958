import axios from "@axios"

export default {
  namespaced: true,
  state: {
    frequencys: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getFrequencysById: (state) => (id) => {
      return state.frequencys.find(frequency => frequency.id === id)
    }
  },
  mutations: {
    SET_FREQUENCYS(state, frequencys) {
      state.frequencys = frequencys
    },
    ADD_FREQUENCY(state, item){
      state.frequencys.unshift(item)
    },
    UPDATE_FREQUENCY(state, frequency) {
      const frequencyIndex = state.frequencys.findIndex((p) => p.id == frequency.data.id)
      Object.assign(state.frequencys[frequencyIndex], frequency.data)
    },
    REMOVE_FREQUENCY(state, id) {
      const frequencyIndex = state.frequencys.findIndex((p) => p.id == id)
      state.frequencys.splice(frequencyIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {

    fetchFrequency({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/frequency-types', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_FREQUENCYS', data)
          } else {
            commit('SET_FREQUENCYS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    storeFrequencys({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/frequency-types', {
              ...data
          }).then((response) => {
              commit('ADD_FREQUENCY', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    updateFrequency(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/frequency-types/${data.id}`, {...data })
          .then((response) => {
            context.commit('UPDATE_FREQUENCY', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    deleteFrequency(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/frequency-types/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_FREQUENCY', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
