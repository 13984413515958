import axios from "@axios"

export default {
  namespaced: true,
  state: {
    categories: [],
    categoryDetails: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getCategoryById: (state) => (id) => {
      return state.categories.find(category => category.id === id)
    }
  },
  mutations: {
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_CATEGORY(state, categoryDetails) {
      state.categoryDetails = categoryDetails
    },
    ADD_CATEGORY(state, item){
      state.categories.unshift(item)
    },
    UPDATE_CATEGORY(state, category) {
      const categoryIndex = state.categories.findIndex((p) => p.id == category.data.id)
      Object.assign(state.categories[categoryIndex], category.data)
    },
    REMOVE_CATEGORY(state, id) {
      const categoryIndex = state.categories.findIndex((p) => p.id == id)
      state.categories.splice(categoryIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchCategories({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/event-types', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_CATEGORIES', data)
          } else {
            commit('SET_CATEGORIES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },
    fetchDetails({ commit }, args){
      return new Promise((resolve, reject) => {
        axios.get(`/index-sub/${args.category_id}`, {
          params: args
        })
        .then(({ data }) => {
            commit('SET_CATEGORY', data)
            resolve(data)
        })
        .catch((error) => {
          reject(error)
        })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("icon", data.icon );
          formData.append("name", data.name );
          formData.append("color", data.color );
          axios.post('/event-types', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then((response) => {
              commit('ADD_CATEGORY', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    storeSub({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/event-types', {
            ...data
        }).then((response) => {
            commit('ADD_CATEGORY', response.data.data)
            resolve(response)
        }).catch(err => {
            return reject(err)
        })
    })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("icon", data.icon );
        formData.append("name", data.name );
        formData.append("color", data.color );
        axios.post(`/update-event/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then((response) => {
            context.commit('UPDATE_CATEGORY', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    updateSub(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/event-types/${data.id}`, {...data })
          .then((response) => {
            context.commit('UPDATE_CATEGORY', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/event-types/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_CATEGORY', response.data) 
            resolve(response) 
          }).catch((error) => {
            reject(error)
          }) 
      }) 
    }, 

  },
}
