import axios from "@axios"

export default {
  namespaced: true,
  state: {
    companyDetails: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getCompanyDetailById: (state) => (id) => {
      return state.companyDetails.find(companyDetail => companyDetail.id === id)
    }
  },
  mutations: {
    SET_COMPANIES(state, companyDetails) {
      state.companyDetails = companyDetails
    },
    ADD_COMPANIE(state, item){
      state.companyDetails.unshift(item)
    },
    UPDATE_COMPANIE(state, companyDetail) {
      // const companyDetailsIndex = state.companyDetails.findIndex((p) => p.id == companyDetail.data.id)
      // Object.assign(state.companyDetails[companyDetailsIndex], companyDetail.data)
    },
    REMOVE_COMPANIE(state, id) {
      const companyDetailIndex = state.companyDetails.findIndex((p) => p.id == id)
      state.companyDetails.splice(companyDetailIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchCompanyDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/company-details', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_COMPANIES', data.data)
          } else {
            commit('SET_COMPANIES', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/company-details/${args.company_details_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                commit('SET_COMPANIES', data.data)
              } else {
                commit('SET_COMPANIES', data)
              }
              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("image", data.image );
        formData.append("company_id", data.company_id );
        formData.append("title", data.title );
        axios.post('/company-details', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_COMPANIE', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.icon){
          formData.append("image", data.image );
        }
        formData.append("company_id", data.company_id );
        formData.append("title", data.title );
        axios.post(`/company-details/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          context.commit('UPDATE_COMPANIE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/company-details/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_COMPANIE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
