import axios from "@axios"

export default {
  namespaced: true,
  state: {
    banners: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getBannersById: (state) => (id) => {
      return state.banners.find(banner => banner.id === id)
    }
  },
  mutations: {
    SET_BANNERS(state, banners) {
      state.banners = banners
    },
    ADD_BANNER(state, item){
      state.banners.unshift(item)
    },
    UPDATE_BANNER(state, banner) {
      const bannerIndex = state.banners.findIndex((p) => p.id == banner.data.id)
      Object.assign(state.banners[bannerIndex], banner.data)
    },
    REMOVE_BANNER(state, id) {
      const bannerIndex = state.banners.findIndex((p) => p.id == id)
      state.banners.splice(bannerIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    async fetchBanner({ commit }, args) {
      
        await axios.get('/banners', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_BANNERS', data)
          } 
          else {
            commit('SET_BANNERS', JSON.parse(JSON.stringify(data.data)))
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          let formData = new FormData();
          formData.append("url", data.url );
          formData.append("duration", data.duration );
          formData.append("whatsapp", data.whatsapp );
          axios.post('/banners', formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
          }).then((response) => {
              commit('ADD_BANNER', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("url", data.url );
        formData.append("duration", data.duration);
        if (data.whatsapp) {
          formData.append("whatsapp", data.whatsapp)
        }
        axios.post(`/update-banner/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          context.commit('UPDATE_BANNER', JSON.parse(JSON.stringify(response.data)))
          resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/banners/${data}`) 
        .then((response) => { 
          context.commit('REMOVE_BANNER', response.data) 
          resolve(response) 
        }).catch((error) => { reject(error) }) 
      }) 
    }, 
  },
}
