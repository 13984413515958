import axios from "@axios"

export default {
  namespaced: true,
  state: {
    terms: [],
    termsStatus: [],
    termsAccept: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {},
  mutations: {
    SET_TERMS(state, terms) {
      state.terms = terms
    },
    SET_TERMS_STATUS(state, termsStatus) {
      state.termsStatus = termsStatus
    },
    SET_TERMS_ACCEPT(state, termsAccept) {
      state.termsAccept = termsAccept
    },
    ADD_TERM(state, item){
      state.terms.unshift(item)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchLestTerm({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/lest-term', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_TERMS', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchTermStatus({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/term-status', {
          params: args,
        }).then(({ data }) => {   
            commit('SET_TERMS_STATUS', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchTermAccept({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/list-accepted', {
          params: args,
        }).then(({ data }) => {  
          if(args.pagination == false){
            commit('SET_TERMS_ACCEPT', data)
          } else {
            commit('SET_TERMS_ACCEPT', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/terms', {
              ...data
          }).then((response) => {
              commit('ADD_TERM', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })

    },
  },
}
