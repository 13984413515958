import axios from "@axios"

export default {
  namespaced: true,
  state: {
    amounts: [],
    amountList: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getAmountsById: (state) => (id) => {
      return state.amounts.find(amount => amount.id === id)
    }
  },
  mutations: {
    SET_AMOUNTS(state, amounts) {
      state.amounts = amounts
    },
    SET_AMOUNTS_LIST(state, amountList) {
      state.amountList = amountList
    },
    ADD_AMOUNT(state, item){
      state.amounts.unshift(item)
    },
    UPDATE_AMOUNT(state, amount) {
      const amountIndex = state.amounts.findIndex((p) => p.id == amount.data.id)
      Object.assign(state.amounts[amountIndex], amount.data)
    },
    REMOVE_AMOUNT(state, id) {
      const amountIndex = state.amounts.findIndex((p) => p.id == id)
      state.amounts.splice(amountIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchAmount({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/amount-types', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_AMOUNTS', data)
          } else {
            commit('SET_AMOUNTS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchAmountList({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/index-painel')
        .then(({ data }) => {
            commit('SET_AMOUNTS_LIST', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    storeAmounts({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/amount-types', {
              ...data
          }).then((response) => {
              commit('ADD_AMOUNT', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    updateAmount(context, data) {
      return new Promise((resolve, reject) => {
          axios.put(`/amount-types/${data.id}`, {...data })
              .then((response) => {
                  context.commit('UPDATE_AMOUNT', response.data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },


    deleteAmount(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/amount-types/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_AMOUNT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 
  },
}
