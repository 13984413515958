import axios from "@axios"

export default {
  namespaced: true,
  state: {
    professionalSuggests: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getProfessionalSuggestById: (state) => (id) => {
      return state.professionalSuggests.find(professionalSuggest => professionalSuggest.id === id)
    }
  },
  mutations: {
    SET_PROFESSIONAL_SUGGESTS(state, professionalSuggests) {
      state.professionalSuggests = professionalSuggests
    },
    REMOVE_PROFESSIONAL_SUGGEST(state, id) {
      const professionalSuggestsIndex = state.professionalSuggests.findIndex((p) => p.id == id)
      state.professionalSuggests.splice(professionalSuggestsIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchProfessionalSuggests({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/professional-suggestions', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_PROFESSIONAL_SUGGESTS', data.data)
          } else {
            commit('SET_PROFESSIONAL_SUGGESTS', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/professional-suggestions/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_PROFESSIONAL_SUGGEST', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
