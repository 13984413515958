import axios from "@axios"

export default {
  namespaced: true,
  state: {
    pharmacies: [],
  },
  getters: {
    getPharmaciesById: (state) => (id) => {
      return state.pharmacies.find(pharmacie => pharmacie.id === id)
    }
  },
  mutations: {
    SET_PHARMACIES(state, pharmacies) {
      state.pharmacies = pharmacies
    },
    ADD_PHARMACIE(state, item){
      state.pharmacies.unshift(item)
    },
    UPDATE_PHARMACIE(state, pharmacie) {
      const pharmacieIndex = state.pharmacies.findIndex((p) => p.id == pharmacie.data.id)
      Object.assign(state.pharmacies[pharmacieIndex], pharmacie.data)
    },
    REMOVE_PHARMACIE(state, id) {
      const pharmacieIndex = state.pharmacies.findIndex((p) => p.id == id)
      state.pharmacies.splice(pharmacieIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchPharmacies({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/pharmacies', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_PHARMACIES', data)
          } else {
            commit('SET_PHARMACIES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/pharmacies/${args.pharmacie_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                  commit('SET_PHARMACIES', data.data)

              } else {
                  commit('SET_PHARMACIES', data)

              }

              commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)

              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/pharmacies', {
              ...data
          }).then((response) => {
              commit('ADD_PHARMACIE', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
          axios.put(`/pharmacies/${data.id}`, {...data })
              .then((response) => {
                  context.commit('UPDATE_PHARMACIE', response.data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/pharmacies/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_PHARMACIE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
