import axios from "@axios"

export default {
  namespaced: true,
  state: {
    urlVideos: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getUrlVideoById: (state) => (id) => {
      return state.urlVideos.find(urlVideo => urlVideo.id === id)
    }
  },
  mutations: {
    SET_URL_VIDEOS(state, urlVideos) {
      state.urlVideos = urlVideos
    },
    ADD_URL_VIDEO(state, item){
      state.urlVideos.unshift(item)
    },
    UPDATE_URL_VIDEO(state, urlVideo) {
      const urlVideoIndex = state.urlVideos.findIndex((p) => p.id == urlVideo.data.id)
      Object.assign(state.urlVideos[urlVideoIndex], urlVideo.data)
    },
    REMOVE_URL_VIDEO(state, id) {
      const urlVideosIndex = state.urlVideos.findIndex((p) => p.id == id)
      state.urlVideos.splice(urlVideosIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchUrlVideos({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/url-videos', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_URL_VIDEOS', data.data)
          } else {
            commit('SET_URL_VIDEOS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/url-videos', {
              ...data
          }).then((response) => {
              commit('ADD_URL_VIDEO', response.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },
    update(context, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/url-videos/${data.id}`, {...data })
          .then((response) => {
            context.commit('UPDATE_URL_VIDEO', response.data)
            resolve(response)
          })
          .catch((error) => { reject(error) })
      })
    },
    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/url-videos/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_URL_VIDEO', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    },
  },
}
