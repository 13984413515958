import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import terms from './terms'
import elderlys from './elderlys'
import responsibles from './responsibles'
import auth from './auth'
import resetPassword from './resetPassword'
import pharmacies from './pharmacie'
import address from './address'
import notification from './notification'
import banners from './banner'
import amounts from './amount'
import frequencys from './frequency'
import medicaments from './medicament'
import dashboards from './dashboard'
import urlVideos from './urlVideo'
import categories from './category'
import plans from './plan'
import companies from './companies'
import professionals from './professionals'
import professionalSuggests from './professionalSuggest'
import segments from './segments'
import companyDetails from './companyDetails'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    terms,
    elderlys,
    responsibles,
    auth,
    resetPassword,
    pharmacies,
    address,
    notification,
    banners,
    amounts,
    frequencys,
    medicaments,
    dashboards,
    urlVideos,
    categories,
    plans,
    companies,
    professionals,
    professionalSuggests,
    segments,
    companyDetails
  },
  strict: process.env.DEV,
})
