import axios from "@axios"

export default {
  namespaced: true,
  state: {
    cities: [],
    states: [],
  },
  getters: {},
  mutations: {
    SET_CITIES(state, cities) {
      state.cities = cities
    },
    SET_STATES(state, states) {
      state.states = states
    },
  },
  actions: {
    fetchCities({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/index-city', {
          params: args,
        }).then(({ data }) => {
            commit('SET_CITIES', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchStates({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/index-state', {
          params: args,
        }).then(({ data }) => {
            commit('SET_STATES', data)
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
  },
}
