import axios from "@axios"

export default {
  namespaced: true,
  state: {
    elderlys: [],
    elderly: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getElderlyById: (state) => (id) => {
      return state.elderlys.find(elderly => elderly.id === id)
    }
  },
  mutations: {
    SET_ELDERLYS(state, elderlys) {
      state.elderlys = elderlys
    },
    SET_ELDERLY(state, elderly) {
      state.elderly = elderly
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }
  },
  actions: {
    fetchElderly({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/elderlys', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_ELDERLYS', data)
          } else {
            commit('SET_ELDERLYS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },
    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/profile-elderly/${args.elderly_id}`, {
              params: args,
          }).then(({ data }) => {
            commit('SET_ELDERLY', data)
            resolve(data)
          }).catch((error) => { reject(error) })
      })
    },
    delete(context, user_id) {
      return new Promise((resolve, reject) => {
          axios.delete(`/elderlys/${user_id}`)  
          .then((response) => { 
            //context.commit('REMOVE_AMOUNT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    },
  },
}
