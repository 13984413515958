import axios from "@axios"

export default {
  namespaced: true,
  state: {
    notifications: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getNotificationById: (state) => (id) => {
      return state.notifications.find(notification => notification.id === id)
    }
  },
  mutations: {
    SET_NOTIFICATIONS(state, notifications) {
      state.notifications = notifications
    },
    ADD_NOTIFICATION(state, item){
      state.notifications.unshift(item)
    },
    UPDATE_NOTIFICATION(state, notification) {
      const notificationIndex = state.notifications.findIndex((p) => p.id == notification.data.id)
      Object.assign(state.notifications[notificationIndex], notification.data)
    },
    REMOVE_NOTIFICATION(state, id) {
      const notificationIndex = state.notifications.findIndex((p) => p.id == id)
      state.notifications.splice(notificationIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchNotifications({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/notifications', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_NOTIFICATIONS', data)
          } else {
            commit('SET_NOTIFICATIONS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/notifications/${args.notification_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                  commit('SET_NOTIFICATIONS', data.data)

              } else {
                  commit('SET_NOTIFICATIONS', data)

              }

              commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)

              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/notifications', {
              ...data
          }).then((response) => {
              commit('ADD_NOTIFICATION', response.data.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
          axios.put(`/notifications/${data.id}`, {...data })
              .then((response) => {
                  context.commit('UPDATE_NOTIFICATION', response.data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/notifications/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_NOTIFICATION', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
