import axios from "@axios"

export default {
  namespaced: true,
  state: {
    responsibles: [],
    responsible: [],
    responsibles_elderlys: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getResponsiblesById: (state) => (id) => {
      return state.responsibles.find(responsible => responsible.id === id)
    }
  },
  mutations: {
    SET_RESPONSIBLES(state, responsibles) {
      state.responsibles = responsibles
    },
    SET_RESPONSIBLE(state, responsible) {
      state.responsible = responsible
    },
    SET_RESPONSIBLES_ELDERLY(state, responsibles_elderlys) {
      state.responsibles_elderlys = responsibles_elderlys
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    },
  },
  actions: {
    fetchResponsibles({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/responsibles', {
          params: args,
        }).then(({ data }) => {
          if(args.pagination == false){
            commit('SET_RESPONSIBLES', data)
          } else {
            commit('SET_RESPONSIBLES', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/profile/${args.responsible_id}`, {
              params: args,
          }).then(({ data }) => {
            commit('SET_RESPONSIBLE', data)
            resolve(data)
          }).catch((error) => { reject(error) })

      })
    },
    delete(context, user_id) {
      return new Promise((resolve, reject) => {
          axios.delete(`/elderlys/${user_id}`)  
          .then((response) => { 
            //context.commit('REMOVE_AMOUNT', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    },
  },
}
