import router from "@/router";
import axios from "@axios";
import moment from "moment";

export default {
	namespaced: true,
	state: {
		users: {},
		totalPages: 0,
	},
	getters: {},
	mutations: {
		SET_BEARER(state, accessToken) {
			localStorage.removeItem(["accessToken", "expiresIn", "refreshToken"]);
			axios.defaults.headers.Authorization = "Bearer " + accessToken;
		},
		UPDATE_USER_INFO(state, users){
			state.users = users
		}
	},
	actions: {
		login({ commit }, payload) {
			const requestBody = {
				email: payload.userDetails.email,
				password: payload.userDetails.password
			}
			return new Promise((resolve, reject) => {
				localStorage.removeItem([
					"accessToken",
					"expiresIn"
				]);
				
				axios.post("auth/login", requestBody)
					.then(response => {
						if(response.data.user.type == "ADMIN"){
							if (response.data.token) {
								localStorage.setItem(
									"accessToken",
									response.data.token
								);
								localStorage.setItem(
									"expiresIn",
									moment(
											new Date(
													Date.now() +
															(response.data.expires_in / 60) *
																	60 *
																	1000
											)
									).format("YYYY-MM-DDTHH:mm:ss")
								);
								
								commit("SET_BEARER", response.data.token);
							}
							if (response.data.user) {
								commit("UPDATE_USER_INFO", response.data.user);
								localStorage.setItem(
									"user", JSON.stringify(response.data.user)
								)
							}
	
							router.push(router.currentRoute.query.to || {name: 'home'});
	
							resolve(response);
						}
						reject({
							message: "Algo deu errado, por favor tente novamente."
						});
					}).catch(() => {
						reject({
							message: "Algo deu errado, por favor tente novamente."
						});
					});
			});
		},
	}
};
