import Vue from 'vue'
import { 
  ToastPlugin, 
  ModalPlugin, 
  BootstrapVue,
  BootstrapVueIcons
} from 'bootstrap-vue'

import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'
import moment from 'moment'

import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css"

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Toast);


// Composition API
Vue.use(VueCompositionAPI)

Vue.filter('dinheiro', function (value) {
  return parseFloat(value).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
});


Vue.filter('dateFormat', function (valor) {
  return moment(String(valor)).format('DD/MM/YYYY')
})

Vue.filter('dayFormat', function (valor) {
  return moment(String(valor)).format('DD')
})

Vue.filter('HourFormat', function (value) {
  const duration = moment.duration(value)
  return moment.utc(duration.as('milliseconds')).format("HH:mm");
})


// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
