import Vue from 'vue'

import router from "@/router";

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

// axios
import axios from 'axios';
// baseurl
const baseURL = process.env.VUE_APP_API_URL || "http://localhost:8000/api";

const axiosIns = axios.create({
  baseURL: baseURL,
})

axiosIns.interceptors.request.use(
  function (config) {
    const token = localStorage.accessToken
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error == 'Error: Network Error') {
      Vue.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon: 'UserXIcon',
          text: "Falha ao se comunicar com o servidor.",
          variant: 'danger',
          timeout: 5000
        },
      });
    } 
    let errorCode = error.response.status;
    switch (errorCode) {
      case 401: {
        const errorMessage = error.response.data.message
        if (errorMessage == 'invalid_credentials') {
          return errorMessage;
        }
        if (errorMessage == 'Token Invalid' || errorMessage == 'User not found' || errorMessage ==
          'Authorization token not found' || errorMessage == 'Unauthorized' || errorMessage == 'Unauthenticated.') {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'UserXIcon',
              text: "Falha na autenticação. Redirecionado ao login!",
              variant: 'danger',
              timeout: 5000
            },
          })
          localStorage.removeItem(["accessToken"]);
          localStorage.removeItem(["user"]);

          router.push("login")
        } else if (errorMessage == 'Token Expired') {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'UserXIcon',
              text: "Por favor, se autentique novamente.",
              variant: 'danger',
              timeout: 5000
            },
          })
          router.push("login")
        }
        break;
      }
      case 422: {
        let validation_messages = error.response.data.message;
        let messageFinal = "";
        for (var key in validation_messages) {
          var obj = validation_messages[key];
          for (var prop in obj) {
            messageFinal += obj[prop] + "\n";
          }
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação',
              icon: 'BellIcon',
              text: messageFinal,
              variant: 'danger',
              timeout: 5000
            },
          })
          messageFinal = "";
        }
        break;
      }
      case 500: {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: 'Erro inesperado ): Por favor, tente novamente.',
            variant: 'danger',
            timeout: 5000
          },
        })
        break;
      }
      default: {
        Vue.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro',
            icon: 'BellIcon',
            text: 'Erro inesperado ): Por favor, tente novamente.',
            variant: 'danger',
            timeout: 5000
          },
        })
        break;
      }
    }

    return Promise.reject(error);
  }
);

Vue.prototype.$http = axiosIns

export default axiosIns
