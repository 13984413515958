import axios from "@axios"

export default {
  namespaced: true,
  state: {
    plans: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getPlansById: (state) => (id) => {
      return state.plans.find(plan => plan.id === id)
    }
  },
  mutations: {
    SET_PLANS(state, plans) {
      state.plans = plans
    },
    ADD_PLAN(state, item){
      state.plans.unshift(item)
    },
    UPDATE_PLAN(state, plan) {
      const planIndex = state.plans.findIndex((p) => p.id == plan.data.id)
      Object.assign(state.plans[planIndex], plan.data)
    },
    REMOVE_PLAN(state, id) {
      const planIndex = state.plans.findIndex((p) => p.id == id)
      state.plans.splice(planIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchPlans({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/plans', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_PLANS', data.data)
          } else {
            commit('SET_PLANS', data.data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/plans/${args.plan_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                  commit('SET_PLANS', data.data)

              } else {
                  commit('SET_PLANS', data)

              }

              commit('SET_TOTAL_PAGES', data.last_page ? data.last_page : 1)

              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
          axios.post('/plans', {
              ...data
          }).then((response) => {
              commit('ADD_PLAN', response.data)
              resolve(response)
          }).catch(err => {
              return reject(err)
          })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
          axios.put(`/plans/${data.id}`, {...data })
              .then((response) => {
                  context.commit('UPDATE_PLAN', response.data)
                  resolve(response)
              })
              .catch((error) => { reject(error) })
      })
  },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/plans/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_PLAN', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
