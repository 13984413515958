import axios from "@axios"

export default {
  namespaced: true,
  state: {
    companies: [],
    companyShow: [],
    pagination: {
      from: 0,
      to: 0,
      page: 1,
      rowsPerPage: 10,
      totalRows: 0
    }
  },
  getters: {
    getCompanieById: (state) => (id) => {
      return state.companies.find(companie => companie.id === id)
    }
  },
  mutations: {
    SET_COMPANIES(state, companies) {
      state.companies = companies
    },
    SET_COMPANY_SHOW(state, companyShow) {
      state.companyShow = companyShow
    },
    ADD_COMPANIE(state, item){
      state.companies.unshift(item)
    },
    UPDATE_COMPANIE(state, companie) {
      const companiesIndex = state.companies.findIndex((p) => p.id == companie.id)
      Object.assign(state.companies[companiesIndex], companie)
    },
    REMOVE_COMPANIE(state, id) {
      const companieIndex = state.companies.findIndex((p) => p.id == id)
      state.companies.splice(companieIndex, 1)
    },
    SET_PAGINATION(state, pagination) {
      state.pagination = pagination
    }

  },
  actions: {
    fetchCompanies({ commit }, args) {
      return new Promise((resolve, reject) => {
        axios.get('/companies', {
          params: args,
        }).then(({ data }) => {   
          if(args.pagination == false){
            commit('SET_COMPANIES', data.data)
          } else {
            commit('SET_COMPANIES', data)
            commit('SET_PAGINATION', {
              from: data.from,
              to: data.to,
              page: data.current_page,
              rowsPerPage: data.per_page,
              totalRows: data.total
            })
          } 
            resolve(data)
        })
        .catch((error) => { reject(error) })
      })
    },

    fetchDetails({ commit }, args) {
      return new Promise((resolve, reject) => {
          axios.get(`/companies/${args.companie_id}`, {
              params: args,
          }).then(({ data }) => {
              if (args.paginated) {
                commit('SET_COMPANY_SHOW', data.data)
              } else {
                commit('SET_COMPANY_SHOW', data)
              }
              resolve(data)
          }).catch((error) => { reject(error) })

      })
    },

    store({ commit }, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("logo", data.logo );
        formData.append("fantasy_name", data.fantasy_name );
        formData.append("segment_id", data.segment_id );
        formData.append("whatsapp", data.whatsapp );
        formData.append("exclusive", data.exclusive );
        formData.append("address[city]", data.address.city );
        formData.append("address[zip_address]", data.address.zip_address );
        formData.append("address[street]", data.address.street );
        formData.append("address[state]", data.address.state );
        formData.append("address[number]", data.address.number );
        formData.append("address[complement]", data.address.complement );
        formData.append("address[neighborhood]", data.address.neighborhood );
        axios.post('/companies', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then((response) => {
          commit('ADD_COMPANIE', response.data)
          resolve(response)
        }).catch(err => {
          return reject(err)
        })
      })
    },

    update(context, data) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        if(data.logo){
          formData.append("logo", data.logo );
        }
        formData.append("fantasy_name", data.fantasy_name );
        formData.append("segment_id", data.segment_id );
        formData.append("whatsapp", data.whatsapp );
        formData.append("exclusive", data.exclusive );
        formData.append("address[city]", data.address.city );
        formData.append("address[zip_address]", data.address.zip_address );
        formData.append("address[street]", data.address.street );
        formData.append("address[state]", data.address.state );
        formData.append("address[number]", data.address.number );
        formData.append("address[complement]", data.address.complement );
        formData.append("address[neighborhood]", data.address.neighborhood );
        axios.post(`/companies/${data.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((response) => {
          context.commit('UPDATE_COMPANIE', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
      })
    },


    delete(context, data) { 
      return new Promise((resolve, reject) => { 
        axios.delete(`/companies/${data}`) 
          .then((response) => { 
            context.commit('REMOVE_COMPANIE', response.data) 
            resolve(response) 
          }).catch((error) => { reject(error) }) 
      }) 
    }, 

  },
}
